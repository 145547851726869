import React, { useCallback, useEffect, useMemo, useState, useTransition, memo } from 'react'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'
import useTheme from '@sport1/react-elements/useTheme'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Touchable from '@sport1/news-styleguide/Touchable'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import dynamic from 'next/dynamic'
import Font from '@sport1/news-styleguide/Font'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import { ThemeProps } from '@sport1/news-styleguide/Types'
import useCurrentDeviceSize from '../../../../../hooks/useCurrentDeviceSize'
import { slugify } from '@/helpers/slugHelper'
import Search from '@/layouts/Standard/Header/components/HeaderBanner/components/Search'
import { Originals } from '@/layouts/Standard/Header/components/Originals'
import { useNavigation } from '@/utils/navigation/Standard'
import { NavigationItem, typeIconMapping } from '@/utils/navigation/Standard/types/NavigationItem'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { sport1Bold } from '@/helpers/fonts'
import WrappedTouchable from '@/components/WrappedTouchable'
import { TEMPORERY_EXATLON_OBJECT } from '@/components/TagList/types'

const BurgerMenu = dynamic(() => import('@/components/BurgerMenu'), { ssr: false })
const PianoHeaderButton = dynamic(
    () => import('@/components/ThirdParty/Piano/Composer/components/HeaderButton'),
    { ssr: false }
)

type Props = {
    renderSearch?: boolean
}

const HeaderBanner: React.FC<Props> = ({ renderSearch = true }) => {
    const { themeName, surfaceColors } = (useTheme() as ThemeProps['theme']) || {}
    const { deviceSizeDesktop, deviceSizeMobile } = useCurrentDeviceSize()
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)
    const [renderBurgerMenu, setRenderBurgerMenu] = useState(false)
    const [renderBurgerMenuItem, setRenderBurgerMenuItem] = useState(false)
    const { navigation, isActive } = useNavigation()
    const { trackInteraction } = useTracking()
    const [, startTransition] = useTransition()

    useEffect(() => setRenderBurgerMenuItem(true), [])

    const startRenderBurgerMenu = useCallback((): void => {
        if (!renderBurgerMenu) {
            startTransition(() => setRenderBurgerMenu(true))
        }
    }, [renderBurgerMenu])

    const onBurgerMenuIconPress = useCallback((): void => {
        startTransition(() => {
            setIsBurgerMenuOpen(prevState => !prevState)
            startRenderBurgerMenu()
        })
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'menu',
            platforms: deviceSizeMobile ? 'mobile' : 'desktop',
        })
    }, [deviceSizeMobile, startRenderBurgerMenu, trackInteraction])

    const onSport1LogoPress = useCallback((): void => {
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'logo',
            platforms: deviceSizeMobile ? 'mobile' : 'desktop',
        })
    }, [trackInteraction, deviceSizeMobile])

    const onTopNavigationItemPress = useCallback(
        (navigationItem: NavigationItem): void => {
            trackInteraction({
                devices: 'all',
                interaction_category: 'navigation',
                interaction_action: 'click',
                interaction_label: `navigation_${slugify(navigationItem.caption)}`,
                platforms: 'desktop',
            })
        },
        [trackInteraction]
    )

    const burgerMenuItem = useMemo(() => {
        if (!renderBurgerMenuItem) return null
        return (
            <div onMouseOver={startRenderBurgerMenu}>
                <Touchable testID="burger-menu-icon" onPress={onBurgerMenuIconPress}>
                    <NonFlexingContainer centerHorizontal>
                        <FontelloIcon
                            name="burger-menu"
                            color={themeName === 'dark' ? colorPattern.pearl : colorPattern.onyx}
                            fontSize="21px"
                        />
                    </NonFlexingContainer>
                </Touchable>
            </div>
        )
    }, [themeName, renderBurgerMenuItem, startRenderBurgerMenu, onBurgerMenuIconPress])

    return (
        <NonFlexingContainer
            height={[44, 44, 78]}
            backgroundColor={surfaceColors?.['background-secondary']}
            horizontal
            centerVertical
            centerHorizontal
            width="100%"
            maxWidth={[undefined, undefined, undefined, 1113]}
            paddingX={['spacing-none', 'spacing-none', 'spacing-none', 'spacing-6']}
            marginX={['spacing-none', 'spacing-none', 'spacing-none', 'auto']}
        >
            <NonFlexingContainer
                position={['absolute', 'absolute', 'relative', 'relative']}
                right={[0, 0, 0, 0]}
                centerHorizontal
                centerVertical
                width="24px"
                height="24px"
                marginRight={['spacing-6', 'spacing-9', 'spacing-9', 'spacing-9']}
                marginLeft={['spacing-none', 'spacing-9', 'spacing-9', 'spacing-none']}
            >
                <BurgerMenu
                    isBurgerMenuOpen={isBurgerMenuOpen}
                    onStateChange={setIsBurgerMenuOpen}
                />
                {burgerMenuItem}
            </NonFlexingContainer>

            <NonFlexingContainer
                position={['absolute', 'absolute', 'relative']}
                left={[12, 16, 0]}
                minWidth={deviceSizeMobile ? 71 : 91}
            >
                <Sport1Logo
                    href="/"
                    onPress={onSport1LogoPress}
                    type={themeName === 'dark' ? 'OnDark' : 'OnLight'}
                    width={deviceSizeMobile ? 71 : 91}
                    testID="header-banner-sport1-logo"
                />
            </NonFlexingContainer>

            {renderSearch && (
                <FlexingContainer
                    right={[60, 60, 0, 0]}
                    width={['24px', '24px', '24px', '50%']}
                    height={['24px', '24px', '24px', '50%']}
                    centerVertical={!deviceSizeDesktop}
                    centerHorizontal={!deviceSizeDesktop}
                    position={['absolute', 'absolute', 'absolute', 'relative']}
                    marginRight={['spacing-none', 'spacing-5', 'spacing-9', 'spacing-9']}
                    marginLeft={['spacing-none', 'spacing-none', 'spacing-none', 'spacing-9']}
                    maxWidth={270}
                >
                    <Search
                        placeholder="Suche"
                        searchUrl={navigation?.search?.queryUrl || ''}
                        popularUrl={navigation?.search?.popularUrl || ''}
                    />
                </FlexingContainer>
            )}

            <FlexingContainer
                display={['none', 'none', 'flex']}
                alignRight={deviceSizeDesktop}
                centerHorizontal={!deviceSizeDesktop}
                marginRight="spacing-11"
            >
                <FlexingContainer
                    display="flex"
                    horizontal
                    centerVertical
                    centerHorizontal
                    width="100%"
                >
                    {navigation?.topNavigation?.slice(1, 5).map(item => {
                        const isActiveItem = isActive(item)
                        let navItemColor

                        if (isActiveItem) {
                            navItemColor = colorPattern.sky
                        } else if (themeName === 'dark') {
                            navItemColor = colorPattern.pearl
                        } else {
                            navItemColor = colorPattern.onyx
                        }
                        const href = item.href || ''
                        return (
                            <NonFlexingContainer key={item.caption} marginRight="spacing-6">
                                <WrappedTouchable
                                    href={href}
                                    onPress={() => onTopNavigationItemPress(item)}
                                    testID="top-navigation-item"
                                >
                                    <NonFlexingContainer
                                        display={['none', 'none', 'flex', 'flex']}
                                        position="relative"
                                        centerHorizontal
                                        centerVertical
                                        minWidth={70}
                                    >
                                        <FontelloIcon
                                            name={`${typeIconMapping.get(item.iconType)}${
                                                isActiveItem ? '-active' : ''
                                            }`}
                                            color={navItemColor}
                                            fontSize="FS20"
                                        />
                                        <Font
                                            fontFamilyVariant="Sport1-Bold"
                                            nextFontFamilyVariant={sport1Bold.style.fontFamily}
                                            fontSize="11px"
                                            lineHeight="lineHeight-16"
                                            color={navItemColor}
                                        >
                                            {item.caption}
                                        </Font>
                                    </NonFlexingContainer>
                                </WrappedTouchable>
                            </NonFlexingContainer>
                        )
                    })}
                    {navigation?.originalsItems && (
                        <NonFlexingContainer
                            centerHorizontal
                            centerVertical
                            minWidth={0}
                            horizontal
                            display={['none', 'none', 'flex', 'flex']}
                            marginRight="spacing-2"
                        >
                            <Originals
                                tags={
                                    !navigation.originalsItems.find(
                                        el => el.title.toLowerCase() === 'exatlon'
                                    )
                                        ? [TEMPORERY_EXATLON_OBJECT, ...navigation.originalsItems]
                                        : navigation.originalsItems
                                }
                            />
                        </NonFlexingContainer>
                    )}
                </FlexingContainer>
            </FlexingContainer>
            <PianoHeaderButton isMobile={deviceSizeMobile} />
        </NonFlexingContainer>
    )
}

export default memo(HeaderBanner)
